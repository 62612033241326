import React from 'react';

// libraries
import { css } from '@emotion/core';
import { Fragment } from 'react';

import Head from 'components/Head';
import Markdown from 'components/Markdown';
import MainLayout from 'components/MainLayout';
import Table from 'components/Table';

import DocsLayout from './Layout';

interface Props {
  pageContext: { value: Enum };
}

export default function EnumDocs(props: Props) {
  const { value } = props.pageContext;

  const children = value.children.filter(child => child.brief);

  return (
    <MainLayout>
      <Head title={value.id} />
      <DocsLayout title={value.id}>
        <Markdown forceBlock>{value.brief || ''}</Markdown>
        <Markdown forceBlock>{value.details}</Markdown>
        {children.length ? (
          <Fragment>
            <h2 css={heading}>Values</h2>
            <Table
              css={{ width: '100%' }}
              columns={['Name', 'Value', 'Description']}
              data={children.map(child => [
                <code>
                  <Markdown>{child.name}</Markdown>
                </code>,
                <code>
                  <Markdown>{child.value}</Markdown>
                </code>,
                <Markdown>{child.brief || ''}</Markdown>
              ])}
            />
          </Fragment>
        ) : null}
      </DocsLayout>
    </MainLayout>
  );
}

const heading = css`
  margin: 4rem 0 2rem;
`;
